<template>
  <b-card>

    <b-row>

      <!-- Role Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!--  & Action Buttons -->
        <div class="d-flex justify-content-start">
          
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0 firstletter" >
                {{ roleData.name }}
              </h4>
              
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-roles-edit', params: { id: roleData._id } }"
                variant="primary"
              >
                Edit
              </b-button>
             
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">
              {{ roleData.name }}
            </td>
          </tr>
                  
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Description</span>
            </th>
            <td class="pb-50">
              {{ roleData.description }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Modules</span>
            </th>
          </tr>

           
          <tr
            v-for="item in roleData.modules"
            :key="item._id"
          >
            <th class="pb-50">
              
            </th>
            <td class="pb-50">
              {{ moduleName(item.module) }}
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {BCard, BButton, BAvatar, BRow, BCol} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import moduleStoreModule from '../../../pages/modules/moduleStoreModule'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },

setup() {
    const MODULE_APP_STORE_MODULE_NAME = 'app-module'
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
    })

    const modules = ref([])
     store.dispatch('app-module/fetchModules')
      .then(response => { 
        modules.value = response.data.modules})

    return {
      modules,
    }
  },
  methods: {
    moduleName(id) {
      const found = this.modules.find(element => element._id == id)
      if (found) {
        return found.name
      }
    },  
  }
}
</script>

<style>
.firstletter::first-letter{
  color: #efb810 !important;
  font-size:2em;
	font-family:serif;
  text-transform: capitalize;
}

</style>
