<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching role data
      </h4>
      <div class="alert-body">
        No role found with this role id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-roles-list'}"
        >
          Role List
        </b-link>
        for other roles.
      </div>
    </b-alert>

    <template v-if="roleData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <role-view-role-info-card :role-data="roleData" />
        </b-col>
        </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import roleStoreModule from '../roleStoreModule'
import RoleViewRoleInfoCard from './RoleViewRoleInfoCard.vue'
import RoleViewRolePermissionsCard from './RoleViewRolePermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    RoleViewRoleInfoCard,
    RoleViewRolePermissionsCard,

    InvoiceList,
  },
  setup() {
    const roleData = ref(null)
    const ROLE_APP_STORE_MODULE_NAME = 'app-role'
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-role/fetchRole', { id: router.currentRoute.params.id })
      .then(response => { 
        roleData.value = response.data.data })
      .catch(error => {
          roleData.value = undefined
      })

    return {
      roleData,
    }
  },
}
</script>

<style>

</style>
